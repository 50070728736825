import {ApiRequest, GetVerb, PostVerb, PutVerb, linksAttachmentsUrl, userLinksAttachmentsUrl} from "@/lib/api";
import timeOperation from "@/utils/timeOperation";
import getLocalTimezone from "@/utils/getLocalTimezone";
import {DocumentsRecordType} from "@/lib/settings";
import { getGridData } from "@/store/searchResults.js";

export default {
  state: {
    linksAttachmentQuickSearchOrderNumbers: [],
    userLinksAttachments: [],
    userLinksAttachmentTypes: [],
    selectedForTaggingLinksAttachmentRootId: 0,
    linksPreviewShowPreviewStatus: true,
    linksPreviewShowDataStatus: true,
    isLinksInFullScreen: false,
  },
  getters: {},
  mutations: {
    setLinksPreviewShowPreviewStatus(state, linksPreviewShowPreviewStatus) {
      state.linksPreviewShowPreviewStatus = linksPreviewShowPreviewStatus || false;
    },
    setLinksPreviewShowDataStatus(state, linksPreviewShowDataStatus) {
      state.linksPreviewShowDataStatus = linksPreviewShowDataStatus || false;
    },
    setLinksAttachmentQuickSearchOrderNumbersData(state, linksAttachmentQuickSearchOrderNumbersData) {
      state.linksAttachmentQuickSearchOrderNumbers = linksAttachmentQuickSearchOrderNumbersData || [];
    },
    clearLinksAttachmentQuickSearchOrderNumbersData(state) {
      state.linksAttachmentQuickSearchOrderNumbers = [];
    },
    setUserLinksAttachmentTypesData(state, userLinksAttachmentTypes) {
      state.userLinksAttachmentTypes = userLinksAttachmentTypes || [];
    },
    addUserLinksAttachmentData(state, userLinksAttachment) {
      if (state.userLinksAttachments != null && userLinksAttachment?.RootId > 0) {
        if (userLinksAttachment.Deleted) {
          for (let userLinksAttachmentIndex = 0; userLinksAttachmentIndex < state.userLinksAttachments.length; userLinksAttachmentIndex++) {
            if (Number(state.userLinksAttachments[userLinksAttachmentIndex].RootId) === Number(userLinksAttachment.RootId)) {
              state.userLinksAttachments.splice(userLinksAttachmentIndex, 1);
              break;
            }
          }
        } else {
          state.userLinksAttachments.push(userLinksAttachment);
        }
      }
    },
  },
  actions: {
    async getLinksAttachmentsAdvancedSearchData(
      context,
      {
        ProductRootId,
        OrganizationRootId,
        UserRootId,
        IncludeMetadata,
        SearchRootId,
        SearchText,
        OrderNumber,
        ContentDispositionPrefix,
        PreviewParameters,
        IncludeDocumentTypeFields,
        IncludePresignedUrl,
        IncludeDebugData,
        linksCanGeneratePublicLinks,
        NoCache,
        CanUseServerCache,
      }
    ) {
      return timeOperation(async () => {
        ProductRootId = Number(ProductRootId || 0);
        OrganizationRootId = Number(OrganizationRootId || 0);
        UserRootId = Number(UserRootId || 0);
        SearchRootId = Number(SearchRootId || 0);
        SearchText = String(SearchText || "");
        OrderNumber = String(OrderNumber || "");
        ContentDispositionPrefix = String(ContentDispositionPrefix || "");
        IncludeMetadata = IncludeMetadata || true;
        IncludeDocumentTypeFields = IncludeDocumentTypeFields || false;
        IncludePresignedUrl = IncludePresignedUrl || false;
        IncludeDebugData = IncludeDebugData || false;
        linksCanGeneratePublicLinks = linksCanGeneratePublicLinks || false;
        NoCache = NoCache || false;
        CanUseServerCache = CanUseServerCache || true;
        let previewParametersJson = PreviewParameters?.length > 0 ? JSON.stringify(PreviewParameters) : "";
        const formattedUrl = `${linksAttachmentsUrl
          }?productrootid=${ProductRootId
          }&organizationrootid=${OrganizationRootId
          }&userrootid=${UserRootId
          }&searchrootid=${SearchRootId
          }&searchtext=${SearchText
          }&ordernumber=${OrderNumber
          }&contentdispositionprefix=${ContentDispositionPrefix
          }&previewparameters=${previewParametersJson
          }&timezone=${encodeURIComponent(getLocalTimezone())
          }&includemetadata=${IncludeMetadata
          }&includedocumenttypefields=${IncludeDocumentTypeFields
          }&includepresignedurl=${IncludePresignedUrl
          }&includedebugdata=${IncludeDebugData
          }&canusecache=${CanUseServerCache}`;
        const Data = getGridData(await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null }));
        Data.linksCanGeneratePublicLinks = linksCanGeneratePublicLinks || false;
        if (NoCache) {
          context.commit("setSearchResultsBasicData", { ViewType: DocumentsRecordType, Data: Data.relatedData, SearchResultsRecords: Data.data });
          return Data;
        }
        context.commit("setSearchResultsData", { ViewType: DocumentsRecordType, Data });
      }, "linksAttachments - getLinksAttachmentsAdvancedSearchData");
    },
    async getLinksAttachmentQuickSearchOrderNumbersSimpleSearchData(context, {
      ProductRootId,
      OrganizationRootId,
      SearchRootId,
      SearchText,
      OrderNumber,
      ReturnOnlyOrderNumbers,
      IncludeDebugData,
      CanUseServerCache,
    }) {
      await timeOperation(async () => {
        ProductRootId = Number(ProductRootId || 0);
        OrganizationRootId = Number(OrganizationRootId || 0);
        SearchRootId = Number(SearchRootId || 0);
        SearchText = String(SearchText || "");
        OrderNumber = String(OrderNumber || "");
        ReturnOnlyOrderNumbers = ReturnOnlyOrderNumbers || false;
        IncludeDebugData = IncludeDebugData || false;
        CanUseServerCache = CanUseServerCache || true;
        const formattedUrl = `${linksAttachmentsUrl
          }?productrootid=${ProductRootId
          }&organizationrootid=${OrganizationRootId
          }&searchrootid=${SearchRootId
          }&searchtext=${SearchText
          }&ordernumber=${OrderNumber
          }&timezone=${encodeURIComponent(getLocalTimezone())
          }&returnonlyordernumbers=${ReturnOnlyOrderNumbers
          }&includedebugdata=${IncludeDebugData
          }&canusecache=${CanUseServerCache}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setLinksAttachmentQuickSearchOrderNumbersData", data);
      }, "linksAttachments - getLinksAttachmentQuickSearchOrderNumbersSimpleSearchData");
    },
    async addUserLinksAttachment(context, userLinksAttachment) {
      await timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: userLinksAttachmentsUrl, Payload: userLinksAttachment });
        context.commit("addUserLinksAttachmentData", data);
      }, "linksAttachments - addUserLinksAttachment");
    },
    async renameLinksAttachment(context, linksAttachment) {
      return timeOperation(async () => {
        const formattedUrl = `${linksAttachmentsUrl}/${Number(linksAttachment?.RootId ?? 0)}/rename`;
        return await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: linksAttachment });
      }, "linksAttachments - renameLinksAttachment");
    },
  },
};
