import {ApiRequest, PostVerb, PutVerb, DeleteVerb, organizationCustomFieldEntriesUrl} from "@/lib/api";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async addOrganizationCustomFieldEntry(context, organizationCustomFieldEntry) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: organizationCustomFieldEntriesUrl, Payload: organizationCustomFieldEntry });
        if (data?.RootId > 0) {
          organizationCustomFieldEntry.RootId = data.RootId;
          context.commit("_addOrganizationCustomFieldEntry", data);
        }
        return data;
      }, "organizationCustomFieldEntries - addOrganizationCustomFieldEntry");
    },
    async updateOrganizationCustomFieldEntry(context, organizationCustomFieldEntry) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationCustomFieldEntriesUrl}/${Number(organizationCustomFieldEntry?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationCustomFieldEntry });
        context.commit("_updateOrganizationCustomFieldEntry", data);
      }, "organizationCustomFieldEntries - updateOrganizationCustomFieldEntry");
    },
    async enableOrganizationCustomFieldEntry(context, organizationCustomFieldEntry) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationCustomFieldEntriesUrl}/${Number(organizationCustomFieldEntry?.RootId ?? 0)}/updateenabledstatus`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: { ...organizationCustomFieldEntry, Enabled: true } });
        context.commit("_updateOrganizationCustomFieldEntryEnabled", data);
      }, "organizationCustomFieldEntries - enableOrganizationCustomFieldEntry");
    },
    async disableOrganizationCustomFieldEntry(context, organizationCustomFieldEntry) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationCustomFieldEntriesUrl}/${Number(organizationCustomFieldEntry?.RootId ?? 0)}/updateenabledstatus`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: { ...organizationCustomFieldEntry, Enabled: false } });
        context.commit("_updateOrganizationCustomFieldEntryEnabled", data);
      }, "organizationCustomFieldEntries - disableOrganizationCustomFieldEntry");
    },
    async updateOrganizationCustomFieldEntryAlias(context, organizationCustomFieldEntry) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationCustomFieldEntriesUrl}/${Number(organizationCustomFieldEntry?.RootId ?? 0)}/updatealias`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationCustomFieldEntry });
        context.commit("_updateOrganizationCustomFieldEntryAlias", data);
      }, "organizationCustomFieldEntries - updateOrganizationCustomFieldEntryAlias");
    },
    async updateOrganizationCustomFieldEntryPositions(context, organizationCustomField) {
      await timeOperation(async () => {
        if (organizationCustomField?.OrganizationCustomFieldEntries?.length > 0) {
          const formattedUrl = `${organizationCustomFieldEntriesUrl}/updatepositions`;
          organizationCustomField.OrganizationCustomFieldEntries = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: organizationCustomField.OrganizationCustomFieldEntries });
          context.commit("_updateOrganizationCustomFieldEntryPositions", organizationCustomField);
        }
      }, "organizationCustomFieldEntries - updateOrganizationCustomFieldEntryPositions");
    },
    async removeOrganizationCustomFieldEntry(context, organizationCustomFieldEntry) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationCustomFieldEntriesUrl}/${Number(organizationCustomFieldEntry?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeOrganizationCustomFieldEntry", organizationCustomFieldEntry);
      }, "organizationCustomFieldEntries - removeOrganizationCustomFieldEntry");
    },
  },
};
