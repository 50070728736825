<template>
  <div>
    <div class="nav-header-home">
      <img class="home-screen-header-image-links" src="../../assets/images/links-white-136w.png" alt="" />
      <div>
        <div>
          <QuickSearch
            v-model:options="autoCompleteOptions"
            :formatFunction="quickSearchResult"
            @search="handleQuickSearch"
            @update:quickSearchOrderNumber="(value) => emit('update:quickSearchOrderNumber', value)"
            @update:quickSearchOrderRootId="(value) => emit('update:quickSearchOrderRootId', value)"
            @update:customSearchText="(value) => emit('update:customSearchText', value)"
            @update:loadQuickSearch="(value) => emit('update:loadQuickSearch', value)"
          />
        </div>
      </div>
      <div v-if="canCreateViews && canViewAdvancedSearch">
        <a @click="emit('advancedSearchClicked')" class="cursor-pointer">
          <svg
            v-tooltip="`Advanced Search`"
            class="w-5 h-5 home-screen-icon"
            data-slot="icon"
            fill="none"
            stroke-width="1.5"
            stroke="white"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607ZM10.5 7.5v6m3-3h-6"></path>
          </svg>
        </a>
      </div>
      <div class="w-full flex justify-content-end align-content-end">
        <div style="color: #ffffff !important" class="text-center mt-1.5 ml-4 text-nowrap">{{ loggedInUserFirstName }}</div>
        <div>
          <UserLoginStatus />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted, nextTick } from "vue";
import { useStore } from "vuex";
import _ from "lodash";

import QuickSearch from "@/components/uIElements/QuickSearch.vue";
import UserLoginStatus from "@/components/UserLoginStatus";

const SHOW_DATA = "Data";
const SHOW_PREVIEW = "Preview";

import {
  TrulyEsqProductRootId,
  DesqProductRootId,
  LinksProductRootId,
  DocumentsTypeCanViewDebugInfoAccessKey,
  OrdersTypeCanViewDebugInfoAccessKey,
  DocumentsTypeHomePreviewDataFieldsConfigKey,
  CanCreateViewsAccessKey,
  DocumentsTypeHomePreviewDocumentsConfigKey,
  CanViewAdvancedSearchAccessKey,
} from "@/lib/settings";

export default {
  name: "LinksNavbar",
  components: {
    QuickSearch,
    UserLoginStatus,
  },
  emits: ["update:quickSearchOrderNumber", "update:quickSearchOrderRootId", "update:customSearchText", "update:loadQuickSearch", "advancedSearchClicked"],
  setup(_props, { emit }) {
    // Vuex
    const store = useStore();
    // State
    const linksAttachmentQuickSearchOrderNumbers = computed(() => store.state.lksAtts.linksAttachmentQuickSearchOrderNumbers);
    const loggedInUser = computed(() => store.state.auth.loggedInUser);
    // Getters
    const getUserConfigSetting = computed(() => store.getters.getUserConfigSetting);
    const getUserAccessSetting = computed(() => store.getters.getUserAccessSetting);
    const getGlobalSelectedProductRootId = computed(() => store.getters.getGlobalSelectedProductRootId);
    const getMergedUserConfigSetting = computed(() => store.getters.getMergedUserConfigSetting);
    function getBooleanAccessSetting(productRootId, settingName) {
      return Boolean(String(getUserAccessSetting.value({ ProductRootId: productRootId, SettingName: settingName })) === "true");
    }
    const canCreateViews = computed(() => getBooleanAccessSetting(getGlobalSelectedProductRootId.value, CanCreateViewsAccessKey));
    const canViewAdvancedSearch = computed(() => getBooleanAccessSetting(getGlobalSelectedProductRootId.value, CanViewAdvancedSearchAccessKey));
    const canViewDebugInfo = computed(() => {
      const productRootId = getGlobalSelectedProductRootId.value;
      switch (productRootId) {
        case TrulyEsqProductRootId:
        case DesqProductRootId:
          return getBooleanAccessSetting(productRootId, OrdersTypeCanViewDebugInfoAccessKey);
        case LinksProductRootId:
          return getBooleanAccessSetting(productRootId, DocumentsTypeCanViewDebugInfoAccessKey);
      }
      return false;
    });
    // Mutations
    const setLinksPreviewShowPreviewStatus = (linksPreviewShowPreviewStatus) => store.commit("setLinksPreviewShowPreviewStatus", linksPreviewShowPreviewStatus);
    const setLinksPreviewShowDataStatus = (linksPreviewShowDataStatus) => store.commit("setLinksPreviewShowDataStatus", linksPreviewShowDataStatus);
    // Actions
    const getLinksAttachmentQuickSearchOrderNumbersSimpleSearchData = async (searchText) => 
      await store.dispatch("getLinksAttachmentQuickSearchOrderNumbersSimpleSearchData", {
        ProductRootId: getGlobalSelectedProductRootId.value,
        OrganizationRootId: Number(loggedInUser.value?.OrganizationRootId || 0),
        SearchRootId: 0,
        SearchText: String(searchText || ""),
        OrderNumber: "",
        ReturnOnlyOrderNumbers: true,
        IncludeDebugData: canViewDebugInfo.value,
      });
    const updateUserConfigSetting = async (userConfigSetting) => await store.dispatch("updateUserConfigSetting", userConfigSetting);

    const autoCompleteOptions = ref([]);
    const lastSearchText = ref("");

    const viewButtons = computed(() => [SHOW_DATA, SHOW_PREVIEW]);

    async function updateConfigurationSetting(settingName, settingValue) {
      let userConfigSetting = getMergedUserConfigSetting.value({
        ProductRootId: getGlobalSelectedProductRootId.value,
        SettingName: settingName,
        SettingValue: String(settingValue),
      });
      await updateUserConfigSetting(userConfigSetting);
    }
    async function handleQuickSearch(searchText) {
      autoCompleteOptions.value = await quickSearchInputChanged(searchText);
    }
    function quickSearchResult(order) {
      return order?.OrderNumber || "";
    }
    async function quickSearchInputChanged(searchText) {
      if (searchText !== lastSearchText.value) {
        lastSearchText.value = searchText;
        if (searchText?.length < 1) {
          return [];
        }
        await getLinksAttachmentQuickSearchOrderNumbersSimpleSearchData(searchText);
        return _.uniq(linksAttachmentQuickSearchOrderNumbers.value.List || []);
      }
      return autoCompleteOptions.value;
    }
    function setViewTypeValues() {
      selectedViewItems.value[SHOW_DATA] = getBooleanConfigSetting(getGlobalSelectedProductRootId.value, DocumentsTypeHomePreviewDataFieldsConfigKey);
      selectedViewItems.value[SHOW_PREVIEW] = getBooleanConfigSetting(getGlobalSelectedProductRootId.value, DocumentsTypeHomePreviewDocumentsConfigKey);
      setLinksPreviewShowPreviewStatus(selectedViewItems.value[SHOW_PREVIEW]);
      setLinksPreviewShowDataStatus(selectedViewItems.value[SHOW_DATA]);
    }
    function getBooleanConfigSetting(productRootId, settingName) {
      return Boolean(String(getUserConfigSetting.value({ ProductRootId: productRootId, SettingName: settingName })) === "true");
    }

    const selectedViewItems = ref({ [SHOW_DATA]: true, [SHOW_PREVIEW]: true });
    const showDataValue = computed(() => selectedViewItems.value[SHOW_DATA]);
    watch(showDataValue, (is) => {
      setLinksPreviewShowDataStatus(is);
      updateConfigurationSetting(DocumentsTypeHomePreviewDataFieldsConfigKey, is);
    });
    const loggedInUserFirstName = computed(() => {
      let firstName = loggedInUser.value?.DisplayName?.split(" ")[0] || "";
      if (firstName.length > 0) {
        return `Welcome, ${firstName}`;
      }
      return "Welcome!";
    });

    const showPreviewValue = computed(() => selectedViewItems.value[SHOW_PREVIEW]);
    watch(showPreviewValue, (is) => {
      setLinksPreviewShowPreviewStatus(is);
      updateConfigurationSetting(DocumentsTypeHomePreviewDocumentsConfigKey, is);
    });

    onMounted(() => {
      nextTick(() => {
        setViewTypeValues();
      });
    });

    return {
      emit,

      autoCompleteOptions,
      canCreateViews,
      canViewAdvancedSearch,
      viewButtons,
      selectedViewItems,
      loggedInUserFirstName,

      handleQuickSearch,
      quickSearchResult,
    };
  },
};
</script>
