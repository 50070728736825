import { ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, searchGroupsUrl } from "@/lib/api";
import timeOperation from "@/utils/timeOperation";
import _ from "lodash";

export default {
  state: {
    cachedUserSearchGroupsForProduct: {},
  },
  mutations: {
    setCachedUserSearchGroupsForProduct(state, { data, ProductRootId, UserRootId, OrganizationRootId, SearchLocationRootId, IncludeMetadata }) {
      state.cachedUserSearchGroupsForProduct[[ProductRootId, UserRootId, OrganizationRootId, SearchLocationRootId, IncludeMetadata].join("-")] = _.cloneDeep(data);
    },
  },
  actions: {
    async getProductSearchGroupsForProduct(context, { ProductRootId, SearchLocationRootId, IncludeMetadata }) {
      return timeOperation(async () => {
        ProductRootId = Number(ProductRootId ?? 0);
        SearchLocationRootId = Number(SearchLocationRootId ?? 0);
        IncludeMetadata = IncludeMetadata ?? true;
        const formattedUrl = `${searchGroupsUrl}?productrootid=${ProductRootId}&organizationrootid=0&userrootid=0&includemetadata=${IncludeMetadata}`;
        return await context.dispatch(ApiRequest, {Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null});
      }, "searchGroups - getProductSearchGroupsForProduct");
    },
    async getOrganizationSearchGroupsForProduct(context, { ProductRootId, OrganizationRootId, SearchLocationRootId, IncludeMetadata }) {
      return timeOperation(async () => {
        ProductRootId = Number(ProductRootId ?? 0);
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        SearchLocationRootId = Number(SearchLocationRootId ?? 0);
        IncludeMetadata = IncludeMetadata ?? true;
        const formattedUrl = `${searchGroupsUrl}?productrootid=${ProductRootId}&organizationrootid=${OrganizationRootId}&userrootid=0&includemetadata=${IncludeMetadata}`;
        return await context.dispatch(ApiRequest, {Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null});
      }, "searchGroups - getOrganizationSearchGroupsForProduct");
    },
    async getUserSearchGroupsForProduct(context, { ProductRootId, UserRootId, OrganizationRootId, SearchLocationRootId, IncludeMetadata, UseCache = false }) {
      if (UseCache) {
        const cachedData = context.state.cachedUserSearchGroupsForProduct?.[[ProductRootId, UserRootId, OrganizationRootId, SearchLocationRootId, IncludeMetadata].join("-")];
        if (cachedData) {
          return cachedData;
        }
      }
      return timeOperation(async () => {
        ProductRootId = Number(ProductRootId ?? 0);
        UserRootId = Number(UserRootId ?? 0);
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        SearchLocationRootId = Number(SearchLocationRootId ?? 0);
        IncludeMetadata = IncludeMetadata ?? true;
        const formattedUrl = `${searchGroupsUrl}?productrootid=${ProductRootId}&organizationrootid=${OrganizationRootId}&userrootid=${UserRootId}&searchlocationrootid=${SearchLocationRootId}&includemetadata=${IncludeMetadata}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setCachedUserSearchGroupsForProduct", { data, ProductRootId, UserRootId, OrganizationRootId, SearchLocationRootId, IncludeMetadata });
        return data;
      }, "searchGroups - getUserSearchGroupsForProduct");
    },
    // async getSearchGroupTemplate(context, { ProductRootId, ProductDataSourceRootId }) {
    //   await timeOperation(async () => {
    //     context.commit("setCachedSearchGroupTemplateProductRootId", ProductRootId);
    //     context.commit("setCachedSearchGroupTemplateProductDataSourceRootId", ProductDataSourceRootId);
    //     const formattedUrl = `${searchGroupsUrl}?productrootid=${ProductRootId}&productdatasourcerootid=${ProductDataSourceRootId}&template=true`;
    //     const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
    //     context.commit("setSearchGroupTemplateData", data);
    //   }, "searchGroups - getSearchGroupTemplate");
    // },
    async addSearchGroup(context, searchGroup) {
      return timeOperation(async () => {
        return await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: searchGroupsUrl, Payload: searchGroup });
      }, "searchGroups - addSearchGroup");
    },
    async updateSearchGroup(context, searchGroup) {
      return timeOperation(async () => {
        const formattedUrl = `${searchGroupsUrl}/${Number(searchGroup?.RootId ?? 0)}`;
        return await context.dispatch(ApiRequest, {Verb: PutVerb, FormattedUrl: formattedUrl, Payload: searchGroup});
      }, "searchGroups - updateSearchGroup");
    },
    async removeSearchGroup(context, searchGroup) {
      return timeOperation(async () => {
        const formattedUrl = `${searchGroupsUrl}/${Number(searchGroup?.RootId ?? 0)}`;
        return await context.dispatch(ApiRequest, {Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null});
      }, "searchGroups - removeSearchGroup");
    },
  },
};
