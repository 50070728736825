<!--suppress CssUnusedSymbol -->
<template>
  <BackgroundOverlay :show="show" :show-background="showBackground" :fadeBackground="fadeBackground">
    <div class="flex">
      <div v-if="showMessage">
        <p :class="showSpinner ? 'mr-2' : ''" class="my-auto">
          {{ message }}
        </p>
      </div>
      <slot></slot>
      <div v-if="showSpinner">
        <!--        <AtomSpinner v-if="spinnerType === ATOM_SPINNER" size="2" />-->
        <!--        <EllipseSpinner v-if="spinnerType === ELLIPSE_SPINNER" />-->
        <!--        <SimpleEllipseSpinner v-if="spinnerType === SIMPLE_ELLIPSE_SPINNER" />-->
        <BrickLoadingSpinner v-if="spinnerType === BRICK_LOADING_SPINNER" />
      </div>
    </div>
  </BackgroundOverlay>
</template>

<script>
import { ref, onMounted, watch, computed } from "vue";
import BackgroundOverlay from "@/components/uIElements/BackgroundOverlay.vue";
// import AtomSpinner from "@/components/uIElements/AtomSpinner";
// import EllipseSpinner from "@/components/uIElements/EllipseSpinner";
// import SimpleEllipseSpinner from "@/components/uIElements/SimpleEllipseSpinner";
import BrickLoadingSpinner from "@/components/uIElements/BrickLoadingSpinner.vue";

const ATOM_SPINNER = "AtomSpinner";
const ELLIPSE_SPINNER = "EllipseSpinner";
const SIMPLE_ELLIPSE_SPINNER = "SimpleEllipseSpinner";
const BRICK_LOADING_SPINNER = "BrickLoadingSpinner";

export default {
  name: "MessageOverlay",
  components: {
    // AtomSpinner,
    // EllipseSpinner,
    // SimpleEllipseSpinner,
    BackgroundOverlay,
    BrickLoadingSpinner,
  },
  props: {
    message: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
    fadeBackground: {
      type: Boolean,
      default: false,
    },
    showSpinner: {
      type: Boolean,
      default: false,
    },
    showMessage: {
      type: Boolean,
      default: false,
    },
    spinnerType: {
      type: String,
      default: BRICK_LOADING_SPINNER,
    },
    timeoutSeconds: {
      type: Number,
      default: null,
    },
    minimumTimeShownSeconds: {
      type: Number,
      default: null,
    },
  },
  emits: ["update:show"],
  setup(props, { emit }) {
    const showThis = ref(props.show);
    const timerIncomplete = ref(false);

    // Functions
    function startTimeout() {
      timerIncomplete.value = true;
      setTimeout(
        () => {
          timerIncomplete.value = false;
          if (!props.show) {
            showThis.value = false;
          } else {
            emit("update:show", false);
          }
        },
        props.minimumTimeShownSeconds ? Math.max(props.timeoutSeconds, props.minimumTimeShownSeconds) * 1000 : props.timeoutSeconds * 1000
      );
    }
    function waitForMinimumTimeShown() {
      timerIncomplete.value = true;
      setTimeout(() => {
        timerIncomplete.value = false;
        showThis.value = props.show;
      }, props.minimumTimeShownSeconds * 1000);
    }

    const localTimeoutSeconds = computed(() => props.timeoutSeconds);
    watch(
      localTimeoutSeconds,
      (is) => {
        if (is) {
          startTimeout();
        }
      },
      {
        deep: true,
      }
    );

    watch(
      () => props.message,
      (is) => {
        if (is) {
          console.log(is);
        }
      }
    );

    const localMinimumTimeShownSeconds = computed(() => props.minimumTimeShownSeconds);
    watch(
      localMinimumTimeShownSeconds,
      (is) => {
        if (is) {
          waitForMinimumTimeShown();
        }
      },
      {
        deep: true,
      }
    );

    const localShow = computed(() => props.show);
    watch(
      localShow,
      (is, was) => {
        showThis.value = timerIncomplete.value ? showThis.value : is;
        if (is && !was && props.minimumTimeShownSeconds) {
          // if we are just starting to show this, show it for the minimum time
          waitForMinimumTimeShown();
        }
      },
      {
        deep: true,
      }
    );

    const showBackground = computed(() => {
      switch (props.spinnerType) {
        case BRICK_LOADING_SPINNER:
        case ATOM_SPINNER:
          return false;
        case ELLIPSE_SPINNER:
        case SIMPLE_ELLIPSE_SPINNER:
          return true;
        default:
          return true;
      }
    });

    onMounted(() => {
      if (props.timeoutSeconds) {
        startTimeout();
      } else if (props.minimumTimeShownSeconds && props.show) {
        // if this is created and immediately shown, show it for the minimum time
        waitForMinimumTimeShown();
      }
    });

    return {
      showThis,
      timerIncomplete,

      ATOM_SPINNER,
      ELLIPSE_SPINNER,
      SIMPLE_ELLIPSE_SPINNER,
      BRICK_LOADING_SPINNER,

      showBackground,

      startTimeout,
      waitForMinimumTimeShown,
    };
  },
};
</script>

<style scoped>
.z-100000000 {
  z-index: 1002;
}
.status-message-bubble-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  background-color: #fff;
  padding: 8px 16px;
  border-radius: calc(0.5 * 32px);
  color: #34406b;
  -webkit-box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 1px 2px rgb(0 0 0 / 23%);
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 1px 2px rgb(0 0 0 / 23%);
  font-size: 13px;
  animation-fill-mode: forwards;
  animation-delay: 0.3s;
}

/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(20px);
  opacity: 0;
}
</style>
