import _ from "lodash";

export default {
  state: {
    orderEditorSummaryFields: [],
  },
  mutations: {
    addOrderEditorSummaryField(state, orderEditorSummaryField) {
      state.orderEditorSummaryFields.push(orderEditorSummaryField);
    },
    removeOrderEditorSummaryField(state, orderEditorSummaryField) {
      _.remove(state.orderEditorSummaryFields, (field) => {
        const normalizedKey = field?.replace?.(/\s+/g, "")?.toLowerCase();
        const normalizedHeaderText = orderEditorSummaryField?.Name?.replace?.(/\s+/g, "")?.toLowerCase();
        return field === orderEditorSummaryField?.Field || normalizedKey === normalizedHeaderText;
      });
    },
    setOrderEditorSummaryField(state, orderEditorSummaryField) {
      state.orderEditorSummaryFields = orderEditorSummaryField;
    },
  },
};
