import { ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, productDocumentTypeTitlesUrl } from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    productDocumentTypeTitles: [],
    productDocumentTypeTitlesCachedProductRootId: 0,
  },
  getters: {
    getNextProductDocumentTypeTitleRootId: (state) => {
      let result = 1;
      state.productDocumentTypeTitles.forEach((productDocumentTypeTitle) => {
        if (Number(productDocumentTypeTitle.RootId) >= result) {
          result = Number(productDocumentTypeTitle.RootId) + 1;
        }
      });
      return result;
    },
    areProductDocumentTypeTitlesLoaded: (state) => (productRootId) => {
      productRootId = Number(productRootId || 0);
      return (
        productRootId > 0 && state.productDocumentTypeTitles?.find((productDocumentTypeTitle) => Number(productDocumentTypeTitle?.ProductRootId || 0) === productRootId) != null
      );
    },
    isProductDocumentTypeTitleNameTaken:
      (state) =>
      (currentProductDocumentTypeTitle, isNew = true) => {
        const { productDocumentTypeTitles } = state;
        if (productDocumentTypeTitles?.length < 1) {
          return false;
        }
        if (!currentProductDocumentTypeTitle) {
          return true;
        }
        const { Name: currentName, RootId } = currentProductDocumentTypeTitle;
        if (!(currentName?.length > 0)) {
          return false;
        }
        if (Number(RootId || 0) < 1 && !isNew) {
          return true;
        }
        return !!productDocumentTypeTitles.find(
          (productDocumentTypeTitle) =>
            String(productDocumentTypeTitle?.Name || "") === String(currentName || "") && Number(RootId || 0) !== Number(productDocumentTypeTitle?.RootId || 0)
        );
      },
  },
  mutations: {
    setProductDocumentTypeTitles(state, productDocumentTypeTitlesData) {
      state.productDocumentTypeTitles = productDocumentTypeTitlesData?.sort((pdttl1, pdttl2) => (pdttl1.Name > pdttl2.Name ? 1 : -1)) ?? [];
    },
    setProductDocumentTypeTitlesCachedProductRootId(state, productDocumentTypeTitlesCachedProductRootId) {
      state.productDocumentTypeTitlesCachedProductRootId = Number(productDocumentTypeTitlesCachedProductRootId || 0);
    },
    _addProductDocumentTypeTitle(state, productDocumentTypeTitle) {
      const productDocumentTypeTitlesData = _.cloneDeep(state.productDocumentTypeTitles) ?? [];
      productDocumentTypeTitlesData.push(productDocumentTypeTitle);
      state.productDocumentTypeTitles = productDocumentTypeTitlesData.sort((pdttl1, pdttl2) => (pdttl1.Name > pdttl2.Name ? 1 : -1)) ?? [];
    },
    _updateProductDocumentTypeTitle(state, updateProductDocumentTypeTitle) {
      const rootId = Number(updateProductDocumentTypeTitle?.RootId ?? 0);
      if (rootId > 0) {
        let productDocumentTypeTitlesData = _.cloneDeep(state.productDocumentTypeTitles) ?? [];
        state.productDocumentTypeTitles =
          [
            ...productDocumentTypeTitlesData.filter((productDocumentTypeTitle) => Number(productDocumentTypeTitle?.RootId ?? 0) !== rootId),
            _.cloneDeep(updateProductDocumentTypeTitle),
          ].sort((pdttl1, pdttl2) => (pdttl1.Name > pdttl2.Name ? 1 : -1)) ?? [];
      }
    },
    _removeProductDocumentTypeTitle(state, deleteProductDocumentTypeTitle) {
      const rootId = Number(deleteProductDocumentTypeTitle?.RootId ?? 0);
      if (rootId > 0) {
        let productDocumentTypeTitlesData = _.cloneDeep(state.productDocumentTypeTitles) ?? [];
        state.productDocumentTypeTitles =
          [...productDocumentTypeTitlesData.filter((productDocumentTypeTitle) => Number(productDocumentTypeTitle?.RootId ?? 0) !== rootId)].sort((pdttl1, pdttl2) =>
            pdttl1.Name > pdttl2.Name ? 1 : -1
          ) ?? [];
      }
    },
  },
  actions: {
    async getProductDocumentTypeTitles(context, { ProductRootId, ProductDocumentTypeRootId, IncludeMetadata, IncludeSystemLevelMetadata, IncludeProductLevelMetadata, UseCache }) {
      return timeOperation(async () => {
        ProductRootId = Number(ProductRootId || 0);
        ProductDocumentTypeRootId = Number(ProductDocumentTypeRootId || 0);
        IncludeMetadata = IncludeMetadata || false;
        IncludeSystemLevelMetadata = IncludeSystemLevelMetadata || false;
        IncludeProductLevelMetadata = IncludeProductLevelMetadata || false;
        UseCache = UseCache || false;
        if (UseCache && context.state.productDocumentTypeTitlesCachedProductRootId === ProductRootId) {
          return;
        }
        const formattedUrl = `${productDocumentTypeTitlesUrl}?productrootid=${ProductRootId}&productdocumenttyperootid=${ProductDocumentTypeRootId}&includemetadata=${IncludeMetadata}&includesystemlevelmetadata=${IncludeSystemLevelMetadata}&includeproductlevelmetadata=${IncludeProductLevelMetadata}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setProductDocumentTypeTitles", data);
        context.commit("setProductDocumentTypeTitlesCachedProductRootId", ProductRootId);
        return data;
      }, "productDocumentTypeTitles - getProductDocumentTypeTitles");
    },
    async addProductDocumentTypeTitles(context, productDocumentTypeTitles) {
      await timeOperation(async () => {
        const formattedUrl = `${productDocumentTypeTitlesUrl}/bulkadd`;
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: productDocumentTypeTitles });
        context.commit("setProductDocumentTypeTitles", data);
      }, "products - addProductDocumentTypeTitle");
    },
    async addProductDocumentTypeTitle(context, productDocumentTypeTitle) {
      await timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: productDocumentTypeTitlesUrl, Payload: productDocumentTypeTitle });
        context.commit("_addProductDocumentTypeTitle", data);
      }, "productDocumentTypeTitles - addProductDocumentTypeTitle");
    },
    async updateProductDocumentTypeTitle(context, productDocumentTypeTitle) {
      await timeOperation(async () => {
        const formattedUrl = `${productDocumentTypeTitlesUrl}/${Number(productDocumentTypeTitle?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: productDocumentTypeTitle });
        context.commit("_updateProductDocumentTypeTitle", data);
      }, "productDocumentTypeTitles - updateProductDocumentTypeTitle");
    },
    async removeProductDocumentTypeTitle(context, productDocumentTypeTitle) {
      await timeOperation(async () => {
        const formattedUrl = `${productDocumentTypeTitlesUrl}/${Number(productDocumentTypeTitle?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeProductDocumentTypeTitle", productDocumentTypeTitle);
      }, "productDocumentTypeTitles - removeProductDocumentTypeTitle");
    },
  },
};
