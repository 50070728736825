import {ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, organizationTagsUrl} from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    organizationDataSourceTags: {},
    cachedOrganizationDataSourceTagProductRootId: 0,
    cachedOrganizationDataSourceTagOrganizationRootId: 0,
  },
  getters: {
    getOrganizationTagByRootId: (state) => (rootId) => {
      const organizationTags = _.reduce(
        state.organizationDataSourceTags,
        (result, listOfOrganizationTags) => {
          if (listOfOrganizationTags?.length > 0) {
            listOfOrganizationTags.forEach((organizationTag) => {
              result.push(organizationTag);
            });
          }
          return result;
        },
        []
      );
      const organizationTag =
        organizationTags.find((organizationTag) => Number(organizationTag?.RootId || 0) === Number(rootId || 0)) || null;
      return organizationTag?.RootId > 0 ? _.cloneDeep(organizationTag) : null;
    },
  },
  mutations: {
    setOrganizationDataSourceTags(state, data) {
      let organizationDataSourceTags = {};
      data?.forEach((organizationTag) => {
        const organizationDataSourceRootId = Number(organizationTag?.OrganizationDataSourceRootId || 0);
        if (organizationDataSourceRootId > 0) {
          if (!organizationDataSourceTags.hasOwnProperty(organizationDataSourceRootId)) {
            organizationDataSourceTags[organizationDataSourceRootId] = [];
          }
          organizationDataSourceTags[organizationDataSourceRootId].push(_.cloneDeep(organizationTag));
        }
      });
      state.organizationDataSourceTags = organizationDataSourceTags;
    },
    clearOrganizationDataSourceTags(state) {
      state.organizationDataSourceTags = {};
    },
    setCachedOrganizationDataSourceTagProductRootId(state, productRootId) {
      state.cachedOrganizationDataSourceTagProductRootId = Number(productRootId || 0);
    },
    setCachedOrganizationDataSourceTagOrganizationRootId(state, organizationRootId) {
      state.cachedOrganizationDataSourceTagOrganizationRootId = Number(organizationRootId || 0);
    },
    _addOrganizationTag(state, organizationTag) {
      if (organizationTag?.RootId > 0) {
        const organizationDataSourceRootId = Number(organizationTag?.OrganizationDataSourceRootId || 0);
        if (state.organizationDataSourceTags.hasOwnProperty(organizationDataSourceRootId)) {
          state.organizationDataSourceTags[organizationDataSourceRootId].push(organizationTag);
          state.organizationDataSourceTags[organizationDataSourceRootId] =
            state.organizationDataSourceTags[organizationDataSourceRootId].sort((ocuf1, ocuf2) => (ocuf1.TagIndex > ocuf2.TagIndex ? 1 : -1)) || [];
        } else {
          state.organizationDataSourceTags[organizationDataSourceRootId] = [organizationTag];
        }
      }
    },
    _updateOrganizationTag(state, updateOrganizationTag) {
      const rootId = Number(updateOrganizationTag?.RootId || 0);
      if (rootId > 0) {
        const organizationDataSourceRootId = Number(updateOrganizationTag?.OrganizationDataSourceRootId || 0);
        if (state.organizationDataSourceTags.hasOwnProperty(organizationDataSourceRootId)) {
          let organizationTagsData = _.cloneDeep(state.organizationDataSourceTags[organizationDataSourceRootId]) || [];
          organizationTagsData = [...organizationTagsData.filter((organizationTag) => Number(organizationTag?.RootId || 0) !== rootId), _.cloneDeep(updateOrganizationTag)] || [];
          state.organizationDataSourceTags[organizationDataSourceRootId] = (_.map(organizationTagsData, (organizationTag) => { return { ...organizationTag, CanEdit: Number(organizationTag.ProductTagRootId || 0) < 1 }}) || []).sort((ocuf1, ocuf2) => (ocuf1.TagIndex > ocuf2.TagIndex ? 1 : -1)) || [];
        } else {
          state.organizationDataSourceTags[organizationDataSourceRootId] = [updateOrganizationTag];
        }
      }
    },
    _updateOrganizationTagEnabled(state, updateOrganizationTag) {
      const rootId = Number(updateOrganizationTag?.RootId || 0);
      if (rootId > 0) {
        const organizationDataSourceRootId = Number(updateOrganizationTag?.OrganizationDataSourceRootId || 0);
        if (state.organizationDataSourceTags.hasOwnProperty(organizationDataSourceRootId)) {
          let organizationTag = state.organizationDataSourceTags[organizationDataSourceRootId]?.find((organizationTag) => Number(organizationTag?.RootId || 0) === rootId) || null;
          if (organizationTag?.RootId > 0) {
            organizationTag.Enabled = updateOrganizationTag.Enabled;
          }
        }
      }
    },
    _updateOrganizationTagAlias(state, updateOrganizationTag) {
      const rootId = Number(updateOrganizationTag?.RootId || 0);
      if (rootId > 0) {
        const organizationDataSourceRootId = Number(updateOrganizationTag?.OrganizationDataSourceRootId || 0);
        if (state.organizationDataSourceTags.hasOwnProperty(organizationDataSourceRootId)) {
          let organizationTag = state.organizationDataSourceTags[organizationDataSourceRootId]?.find((organizationTag) => Number(organizationTag?.RootId || 0) === rootId) || null;
          if (organizationTag?.RootId > 0) {
            organizationTag.Alias = updateOrganizationTag.Alias;
          }
        }
      }
    },
    _removeOrganizationTag(state, deleteOrganizationTag) {
      const rootId = Number(deleteOrganizationTag?.RootId || 0);
      if (rootId > 0) {
        const organizationDataSourceRootId = Number(deleteOrganizationTag?.OrganizationDataSourceRootId || 0);
        if (state.organizationDataSourceTags.hasOwnProperty(organizationDataSourceRootId)) {
          let organizationTagsData = _.cloneDeep(state.organizationDataSourceTags[organizationDataSourceRootId]) || [];
          organizationTagsData =
            [...organizationTagsData.filter((organizationTag) => Number(organizationTag?.RootId || 0) !== rootId)] || [];
          state.organizationDataSourceTags[organizationDataSourceRootId] =
            (
              _.map(organizationTagsData, (organizationTag) => {
                return { ...organizationTag, CanEdit: Number(organizationTag.ProductTagRootId || 0) < 1 };
              }) || []
            ).sort((ocuf1, ocuf2) => (ocuf1.TagIndex > ocuf2.TagIndex ? 1 : -1)) || [];
        }
      }
    },
  },
  actions: {
    async getOrganizationTags(context, { 
      ProductRootId, 
      OrganizationRootId, 
      UseCache,
      CanUseServerCache }) 
    {
      ProductRootId = Number(ProductRootId || 0);
      OrganizationRootId = Number(OrganizationRootId || 0);
      UseCache = UseCache || false;
      CanUseServerCache = CanUseServerCache || true;
      const cachedProductRootId = Number(context.state.cachedOrganizationDataSourceTagProductRootId || 0);
      const cachedOrganizationRootId = Number(context.state.cachedOrganizationDataSourceTagOrganizationRootId || 0);
      const dirtyCache = !((cachedProductRootId === ProductRootId) && (cachedOrganizationRootId === OrganizationRootId));
      if (!UseCache || dirtyCache) {
        context.commit("setCachedOrganizationDataSourceTagProductRootId", ProductRootId);
        context.commit("setCachedOrganizationDataSourceTagOrganizationRootId", OrganizationRootId);
        await timeOperation(async () => {
          context.commit("setOrganizationDataSourceTags", []);
          const formattedUrl = `${organizationTagsUrl
            }?productrootid=${ProductRootId
            }&organizationrootid=${OrganizationRootId
            }&organizationdatasourcerootid=0&canusecache=${CanUseServerCache
            }&includemetadata=true`;
          const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
          context.commit("setOrganizationDataSourceTags", data);
        }, "organizationTags - getOrganizationTags");
      }
    },
    async addOrganizationTag(context, organizationTag) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: organizationTagsUrl, Payload: organizationTag });
        if (data?.RootId > 0) {
          organizationTag.RootId = data.RootId;
          context.commit("_addOrganizationTag", data);
        }
        return data;
      }, "organizationTags - addOrganizationTag");
    },
    async updateOrganizationTag(context, organizationTag) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationTagsUrl}/${Number(organizationTag?.RootId || 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationTag });
        context.commit("_updateOrganizationTag", data);
      }, "organizationTags - updateOrganizationTag");
    },
    async enableOrganizationTag(context, organizationTag) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationTagsUrl}/${Number(organizationTag?.RootId || 0)}/updateenabledstatus`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: { ...organizationTag, Enabled: true } });
        context.commit("_updateOrganizationTagEnabled", data);
      }, "organizationTags - enableOrganizationTag");
    },
    async disableOrganizationTag(context, organizationTag) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationTagsUrl}/${Number(organizationTag?.RootId || 0)}/updateenabledstatus`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: { ...organizationTag, Enabled: false } });
        context.commit("_updateOrganizationTagEnabled", data);
      }, "organizationTags - disableOrganizationTag");
    },
    async updateOrganizationTagAlias(context, organizationTag) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationTagsUrl}/${Number(organizationTag?.RootId || 0)}/updatealias`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationTag });
        context.commit("_updateOrganizationTagAlias", data);
      }, "organizationTags - updateOrganizationTagAlias");
    },
    async removeOrganizationTag(context, organizationTag) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationTagsUrl}/${Number(organizationTag?.RootId || 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeOrganizationTag", organizationTag);
      }, "organizationTags - removeOrganizationTag");
    },
  },
};
