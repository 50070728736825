import {ApiRequest, GetVerb, PostVerb, DeleteVerb, directUploadsUrl} from "@/lib/api";
import timeOperation from "@/utils/timeOperation";
import _ from "lodash";

export default {
  state: {
    recentlyUploaded: [],
  },
  mutations: {
    setRecentlyUploaded(state, recentlyUploadedData) {
      state.recentlyUploaded = recentlyUploadedData ?? [];
    },
    clearRecentlyUploaded(state) {
      state.recentlyUploaded = [];
    },
    _updateRecentUploads(state, updateLinksQueuedAttachments) {
      if (updateLinksQueuedAttachments?.length > 0) {
        updateLinksQueuedAttachments.forEach((updateLinksQueuedAttachment) => {
          let recentUpload = state.recentlyUploaded.find((recentUpload) => Number(recentUpload?.RecordId ?? 0) === Number(updateLinksQueuedAttachment?.RootId ?? 0)) ?? null;
          if (recentUpload) {
            recentUpload.AssociatedLinksQueuedAttachment = _.cloneDeep(updateLinksQueuedAttachment);
          }
        });
      }
    },
  },
  actions: {
    async getDirectUploadPresignedUrl(context, params) {
      return timeOperation(async () => {
        const fileUploadId = encodeURIComponent(params?.FileUploadId ?? "");
        const fileName = encodeURIComponent(params?.FileName ?? "");
        const mimeType = encodeURIComponent(params?.MimeType ?? "");
        const formattedUrl = `${directUploadsUrl
          }?productrootid=${Number(params?.ProductRootId ?? 0)
          }&orderrootid=${Number(params?.OrderRootId ?? 0)
          }&recordtablename=${String(params?.RecordTableName ?? "")
          }&fileuploadid=${fileUploadId
          }&filename=${fileName
          }&mimetype=${mimeType
          }&contentsize=${Number(params?.ContentSize ?? 0)}`;
        return await context.dispatch(ApiRequest, {Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null});
      }, "directUploads - getDirectUploadPresignedUrl");
    },
    async getRecentUploads(context, directUploadIds) {
      await timeOperation(async () => {
        const formattedUrl = `${directUploadsUrl}/recentlyuploaded?directuploadids=${directUploadIds}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setRecentlyUploaded", data);
      }, "directUploads - getRecentUploads");
    },
    async getRecentUploadsList(context, directUploadIds) {
      await timeOperation(async () => {
        if (directUploadIds?.length > 0) {
          try {
            const formattedIds = directUploadIds.join("-");
            const formattedUrl = `${directUploadsUrl}/recentlyuploaded?directuploadids=${formattedIds}`;
            const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
            context.commit("setRecentlyUploaded", data);
          } catch (e) {
            console.error("search for recently uploaded failed ", e);
          }
        }
      }, "directUploads - getRecentUploadsList");
    },
    async directUploadCompleted(context, directUpload) {
      return timeOperation(async () => {
        return await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: directUploadsUrl, Payload: directUpload });
      }, "directUploads - directUploadCompleted");
    },
    async removeRecentUploads(context, directUploadIds) {
      await timeOperation(async () => {
        const formattedUrl = `${directUploadsUrl}/recentlyuploaded?directuploadids=${directUploadIds}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
      }, "directUploads - removeRecentUploads");
    },
    async removeRecentUploadsList(context, directUploadIds) {
      await timeOperation(async () => {
        if (directUploadIds?.length > 0) {
          try {
            const formattedDirectUploadIds = directUploadIds.join("-");
            const formattedUrl = `${directUploadsUrl}/recentlyuploaded?directuploadids=${formattedDirectUploadIds}`;
            await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
          } catch (e) {
            console.error("cannot remove uploads ", e);
          }
        }
      }, "directUploads - removeRecentUploadsList");
    },
  },
};
