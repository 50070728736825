import { ApiRequest, PostVerb, imageUrl } from "@/lib/api";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async createS3Record(context, { Base64EncodedImage, Base64EncodedImageContentType }) {
      return timeOperation(async () => {
        const image = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: imageUrl, Payload: { Base64EncodedImage, Base64EncodedImageContentType } });
        return { PresignedUrl: image?.PresignedUrl ?? "", S3Key: image?.S3Key ?? "", S3ContentType: image?.S3ContentType ?? "" };
      }, "image - createS3Record");
    },
  },
};
