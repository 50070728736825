import {ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, organizationCustomFieldsUrl} from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    organizationDataSourceCustomFields: {},
    cachedOrganizationDataSourcesCustomFieldProductRootId: -1,
    cachedOrganizationDataSourcesCustomFieldOrganizationRootId: -1,
  },
  getters: {
    getOrganizationCustomFieldByRootId: (state) => (rootId) => {
      const organizationCustomFields = _.reduce(
        state.organizationDataSourceCustomFields,
        (result, listOfOrganizationCustomFields) => {
          if (listOfOrganizationCustomFields?.length > 0) {
            listOfOrganizationCustomFields.forEach((organizationCustomField) => {
              result.push(organizationCustomField);
            });
          }
          return result;
        },
        []
      );
      const organizationCustomField = organizationCustomFields.find((organizationCustomField) => Number(organizationCustomField?.RootId || 0) === Number(rootId || 0)) || null;
      return organizationCustomField?.RootId > 0 ? _.cloneDeep(organizationCustomField) : null;
    },
    getOrganizationCustomFieldEntries: (state) => (OrganizationCustomFieldRootId) => {
      OrganizationCustomFieldRootId = Number(OrganizationCustomFieldRootId || 0);
      if (OrganizationCustomFieldRootId > 0) {
        const organizationCustomFields = _.reduce(
          state.organizationDataSourceCustomFields,
          (result, listOfOrganizationCustomFields) => {
            if (listOfOrganizationCustomFields?.length > 0) {
              listOfOrganizationCustomFields.forEach((organizationCustomField) => {
                result.push(organizationCustomField);
              });
            }
            return result;
          },
          []
        );
        const parentOrganizationCustomField = organizationCustomFields.find((organizationCustomField) => Number(organizationCustomField?.RootId || 0) === OrganizationCustomFieldRootId);
        if (parentOrganizationCustomField) {
          return _.cloneDeep(parentOrganizationCustomField.OrganizationCustomFieldEntries) || [];
        }
      }
      return [];
    },
  },
  mutations: {
    setOrganizationDataSourceCustomFields(state, data) {
      let organizationDataSourceCustomFields = {};
      data?.forEach((organizationCustomField) => {
        const organizationDataSourceRootId = Number(organizationCustomField?.OrganizationDataSourceRootId || 0);
        if (organizationDataSourceRootId > 0) {
          if (!organizationDataSourceCustomFields.hasOwnProperty(organizationDataSourceRootId)) {
            organizationDataSourceCustomFields[organizationDataSourceRootId] = [];
          }
          organizationDataSourceCustomFields[organizationDataSourceRootId].push(_.cloneDeep(organizationCustomField));
        }
      });
      state.organizationDataSourceCustomFields = organizationDataSourceCustomFields;
    },
    clearOrganizationDataSourceCustomFields(state) {
      state.organizationDataSourceCustomFields = {};
      state.cachedOrganizationDataSourcesCustomFieldProductRootId = -1;
      state.cachedOrganizationDataSourcesCustomFieldOrganizationRootId = -1;
    },
    setCachedOrganizationDataSourcesCustomFieldProductRootId(state, cachedOrganizationDataSourcesCustomFieldProductRootId) {
      state.cachedOrganizationDataSourcesCustomFieldProductRootId = Number(cachedOrganizationDataSourcesCustomFieldProductRootId || -1);
    },
    setCachedOrganizationDataSourcesCustomFieldOrganizationRootId(state, cachedOrganizationDataSourcesCustomFieldOrganizationRootId) {
      state.cachedOrganizationDataSourcesCustomFieldOrganizationRootId = Number(cachedOrganizationDataSourcesCustomFieldOrganizationRootId || -1);
    },
    _addOrganizationCustomField(state, organizationCustomField) {
      if (organizationCustomField?.RootId > 0) {
        const organizationDataSourceRootId = Number(organizationCustomField?.OrganizationDataSourceRootId || 0);
        if (state.organizationDataSourceCustomFields.hasOwnProperty(organizationDataSourceRootId)) {
          state.organizationDataSourceCustomFields[organizationDataSourceRootId].push(organizationCustomField);
          state.organizationDataSourceCustomFields[organizationDataSourceRootId] =
            state.organizationDataSourceCustomFields[organizationDataSourceRootId].sort((ocf1, ocf2) => (ocf1.CustomFieldIndex > ocf2.CustomFieldIndex ? 1 : -1)) || [];
        } else {
          state.organizationDataSourceCustomFields[organizationDataSourceRootId] = [organizationCustomField];
        }
      }
    },
    _updateOrganizationCustomField(state, updateOrganizationCustomField) {
      const rootId = Number(updateOrganizationCustomField?.RootId || 0);
      if (rootId > 0) {
        const organizationDataSourceRootId = Number(updateOrganizationCustomField?.OrganizationDataSourceRootId || 0);
        if (state.organizationDataSourceCustomFields.hasOwnProperty(organizationDataSourceRootId)) {
          let organizationCustomFieldsData = _.cloneDeep(state.organizationDataSourceCustomFields[organizationDataSourceRootId]) || [];
          organizationCustomFieldsData =
            [
              ...organizationCustomFieldsData.filter((organizationCustomField) => Number(organizationCustomField?.RootId || 0) !== rootId),
              _.cloneDeep(updateOrganizationCustomField),
            ] || [];
          state.organizationDataSourceCustomFields[organizationDataSourceRootId] =
            (
              _.map(organizationCustomFieldsData, (organizationCustomField) => {
                return { ...organizationCustomField, CanEdit: Number(organizationCustomField.ProductCustomFieldRootId || 0) < 1 };
              }) || []
            ).sort((ocf1, ocf2) => (ocf1.CustomFieldIndex > ocf2.CustomFieldIndex ? 1 : -1)) || [];
        } else {
          state.organizationDataSourceCustomFields[organizationDataSourceRootId] = [updateOrganizationCustomField];
        }
      }
    },
    _updateOrganizationCustomFieldEnabled(state, updateOrganizationCustomField) {
      const rootId = Number(updateOrganizationCustomField?.RootId || 0);
      if (rootId > 0) {
        const organizationDataSourceRootId = Number(updateOrganizationCustomField?.OrganizationDataSourceRootId || 0);
        if (state.organizationDataSourceCustomFields.hasOwnProperty(organizationDataSourceRootId)) {
          let organizationCustomField =
            state.organizationDataSourceCustomFields[organizationDataSourceRootId]?.find((organizationCustomField) => Number(organizationCustomField?.RootId || 0) === rootId) ||
            null;
          if (organizationCustomField?.RootId > 0) {
            organizationCustomField.Enabled = updateOrganizationCustomField.Enabled;
          }
        }
      }
    },
    _updateOrganizationCustomFieldAlias(state, updateOrganizationCustomField) {
      const rootId = Number(updateOrganizationCustomField?.RootId || 0);
      if (rootId > 0) {
        const organizationDataSourceRootId = Number(updateOrganizationCustomField?.OrganizationDataSourceRootId || 0);
        if (state.organizationDataSourceCustomFields.hasOwnProperty(organizationDataSourceRootId)) {
          let organizationCustomField =
            state.organizationDataSourceCustomFields[organizationDataSourceRootId]?.find((organizationCustomField) => Number(organizationCustomField?.RootId || 0) === rootId) ||
            null;
          if (organizationCustomField?.RootId > 0) {
            organizationCustomField.Alias = updateOrganizationCustomField.Alias;
          }
        }
      }
    },
    _removeOrganizationCustomField(state, deleteOrganizationCustomField) {
      const rootId = Number(deleteOrganizationCustomField?.RootId || 0);
      if (rootId > 0) {
        const organizationDataSourceRootId = Number(deleteOrganizationCustomField?.OrganizationDataSourceRootId || 0);
        if (state.organizationDataSourceCustomFields.hasOwnProperty(organizationDataSourceRootId)) {
          let organizationCustomFieldsData = _.cloneDeep(state.organizationDataSourceCustomFields[organizationDataSourceRootId]) || [];
          organizationCustomFieldsData = [...organizationCustomFieldsData.filter((organizationCustomField) => Number(organizationCustomField?.RootId || 0) !== rootId)] || [];
          state.organizationDataSourceCustomFields[organizationDataSourceRootId] =
            (
              _.map(organizationCustomFieldsData, (organizationCustomField) => {
                return { ...organizationCustomField, CanEdit: Number(organizationCustomField.ProductCustomFieldRootId || 0) < 1 };
              }) || []
            ).sort((ocf1, ocf2) => (ocf1.CustomFieldIndex > ocf2.CustomFieldIndex ? 1 : -1)) || [];
        }
      }
    },
    _addOrganizationCustomFieldEntry(state, organizationCustomFieldEntry) {
      const organizationCustomFieldRootId = Number(organizationCustomFieldEntry?.OrganizationCustomFieldRootId || 0);
      const organizationCustomFieldEntryRootId = Number(organizationCustomFieldEntry?.RootId || 0);
      if ((organizationCustomFieldRootId > 0) && (organizationCustomFieldEntryRootId > 0)) {
        const organizationDataSourceRootIds = Object.keys(state.organizationDataSourceCustomFields);
        organizationDataSourceRootIds?.forEach((organizationDataSourceRootId) => {
          const parentOrganizationCustomField = state.organizationDataSourceCustomFields[organizationDataSourceRootId]?.find((organizationCustomField) => Number(organizationCustomField?.RootId || 0) === organizationCustomFieldRootId);
          if (parentOrganizationCustomField) {
            let parentOrganizationCustomFieldEntries = _.cloneDeep(parentOrganizationCustomField.OrganizationCustomFieldEntries) || [];
            parentOrganizationCustomFieldEntries.push(organizationCustomFieldEntry);
            parentOrganizationCustomField.OrganizationCustomFieldEntries = parentOrganizationCustomFieldEntries.sort((ocfe1, ocfe2) => (ocfe1.Position > ocfe2.Position ? 1 : -1)) || [];
          }
        });
      }
    },
    _updateOrganizationCustomFieldEntry(state, updateOrganizationCustomFieldEntry) {
      const organizationCustomFieldRootId = Number(updateOrganizationCustomFieldEntry?.OrganizationCustomFieldRootId || 0);
      const organizationCustomFieldEntryRootId = Number(updateOrganizationCustomFieldEntry?.RootId || 0);
      if ((organizationCustomFieldRootId > 0) && (organizationCustomFieldEntryRootId > 0)) {
        const organizationDataSourceRootIds = Object.keys(state.organizationDataSourceCustomFields);
        organizationDataSourceRootIds?.forEach((organizationDataSourceRootId) => {
          const parentOrganizationCustomField = state.organizationDataSourceCustomFields[organizationDataSourceRootId]?.find((organizationCustomField) => Number(organizationCustomField?.RootId || 0) === organizationCustomFieldRootId);
          if (parentOrganizationCustomField) {
            updateOrganizationCustomFieldEntry.CanEdit = !(Number(updateOrganizationCustomFieldEntry.ProductCustomFieldRootId || 0) > 0);
            let parentOrganizationCustomFieldEntries = _.cloneDeep(parentOrganizationCustomField.OrganizationCustomFieldEntries) || [];
            parentOrganizationCustomFieldEntries = [...parentOrganizationCustomFieldEntries.filter((organizationCustomFieldEntry) => Number(organizationCustomFieldEntry?.RootId || 0) !== organizationCustomFieldEntryRootId), _.cloneDeep(updateOrganizationCustomFieldEntry)].sort((ocfe1, ocfe2) => (ocfe1.Position > ocfe2.Position ? 1 : -1)) || [];
            parentOrganizationCustomField.OrganizationCustomFieldEntries = parentOrganizationCustomFieldEntries;
          }
        });
      }
    },
    _updateOrganizationCustomFieldEntryEnabled(state, updateOrganizationCustomFieldEntry) {
      const organizationCustomFieldRootId = Number(updateOrganizationCustomFieldEntry?.OrganizationCustomFieldRootId || 0);
      const organizationCustomFieldEntryRootId = Number(updateOrganizationCustomFieldEntry?.RootId || 0);
      if ((organizationCustomFieldRootId > 0) && (organizationCustomFieldEntryRootId > 0)) {
        const organizationDataSourceRootIds = Object.keys(state.organizationDataSourceCustomFields);
        organizationDataSourceRootIds?.forEach((organizationDataSourceRootId) => {
          const parentOrganizationCustomField = state.organizationDataSourceCustomFields[organizationDataSourceRootId]?.find((organizationCustomField) => Number(organizationCustomField?.RootId || 0) === organizationCustomFieldRootId);
          if (parentOrganizationCustomField) {
            const foundOrganizationCustomFieldEntry = parentOrganizationCustomField.OrganizationCustomFieldEntries?.find((organizationCustomFieldEntry) => Number(organizationCustomFieldEntry?.RootId || 0) === organizationCustomFieldEntryRootId);
            if (foundOrganizationCustomFieldEntry) {
              foundOrganizationCustomFieldEntry.Enabled = updateOrganizationCustomFieldEntry.Enabled;
            }
          }
        });
      }
    },
    _updateOrganizationCustomFieldEntryAlias(state, updateOrganizationCustomFieldEntry) {
      const organizationCustomFieldRootId = Number(updateOrganizationCustomFieldEntry?.OrganizationCustomFieldRootId || 0);
      const organizationCustomFieldEntryRootId = Number(updateOrganizationCustomFieldEntry?.RootId || 0);
      if ((organizationCustomFieldRootId > 0) && (organizationCustomFieldEntryRootId > 0)) {
        const organizationDataSourceRootIds = Object.keys(state.organizationDataSourceCustomFields);
        organizationDataSourceRootIds?.forEach((organizationDataSourceRootId) => {
          const parentOrganizationCustomField = state.organizationDataSourceCustomFields[organizationDataSourceRootId]?.find((organizationCustomField) => Number(organizationCustomField?.RootId || 0) === organizationCustomFieldRootId);
          if (parentOrganizationCustomField) {
            const foundOrganizationCustomFieldEntry = parentOrganizationCustomField.OrganizationCustomFieldEntries?.find((organizationCustomFieldEntry) => Number(organizationCustomFieldEntry?.RootId || 0) === organizationCustomFieldEntryRootId);
            if (foundOrganizationCustomFieldEntry) {
              foundOrganizationCustomFieldEntry.Alias = updateOrganizationCustomFieldEntry.Alias || "";
            }
          }
        });
      }
    },
    _updateOrganizationCustomFieldEntryPositions(state, updatedOrganizationCustomField) {
      const organizationCustomFieldRootId = Number(updatedOrganizationCustomField?.RootId || 0);
      if (organizationCustomFieldRootId > 0) {
        state.organizationDataSourceCustomFields?.forEach((organizationCustomFields) => {
          const parentOrganizationCustomField = organizationCustomFields?.find((organizationCustomField) => Number(organizationCustomField?.RootId || 0) === organizationCustomFieldRootId);
          if (parentOrganizationCustomField) {
            let parentOrganizationCustomFieldEntries = _.cloneDeep(updatedOrganizationCustomField.OrganizationCustomFieldEntries) || [];
            parentOrganizationCustomField.OrganizationCustomFieldEntries = parentOrganizationCustomFieldEntries.sort((ocfe1, ocfe2) => (ocfe1.Position > ocfe2.Position ? 1 : -1)) || [];
          }
        });
      }
    },
    _removeOrganizationCustomFieldEntry(state, deleteOrganizationCustomFieldEntry) {
      const organizationCustomFieldRootId = Number(deleteOrganizationCustomFieldEntry?.OrganizationCustomFieldRootId || 0);
      const organizationCustomFieldEntryRootId = Number(deleteOrganizationCustomFieldEntry?.RootId || 0);
      if ((organizationCustomFieldRootId > 0) && (organizationCustomFieldEntryRootId > 0)) {
        const organizationDataSourceRootIds = Object.keys(state.organizationDataSourceCustomFields);
        organizationDataSourceRootIds?.forEach((organizationDataSourceRootId) => {
          const parentOrganizationCustomField = state.organizationDataSourceCustomFields[organizationDataSourceRootId]?.find((organizationCustomField) => Number(organizationCustomField?.RootId || 0) === organizationCustomFieldRootId);
          if (parentOrganizationCustomField) {
            let parentOrganizationCustomFieldEntries = _.cloneDeep(parentOrganizationCustomField.OrganizationCustomFieldEntries) || [];
            parentOrganizationCustomFieldEntries = [...parentOrganizationCustomFieldEntries.filter((organizationCustomFieldEntry) => Number(organizationCustomFieldEntry?.RootId || 0) !== organizationCustomFieldEntryRootId)] || [];
            parentOrganizationCustomField.OrganizationCustomFieldEntries = parentOrganizationCustomFieldEntries;
          }
        });
      }
    }
  },
  actions: {
    async getOrganizationCustomFields(context, { ProductRootId, OrganizationRootId, UseCache, CanUseServerCache }) {
      ProductRootId = Number(ProductRootId || 0);
      OrganizationRootId = Number(OrganizationRootId || 0);
      UseCache = UseCache || false;
      CanUseServerCache = CanUseServerCache || true;
      if ((OrganizationRootId > 0) && (ProductRootId > 0)) {
        const cachedProductRootId = Number(context.state.cachedOrganizationDataSourcesCustomFieldProductRootId || 0);
        const cachedOrganizationRootId = Number(context.state.cachedOrganizationDataSourcesCustomFieldOrganizationRootId || 0);
        const dirtyCache = !((ProductRootId === cachedProductRootId) && (OrganizationRootId === cachedOrganizationRootId));
        if (!UseCache || dirtyCache) {
          context.commit("setCachedOrganizationDataSourcesCustomFieldProductRootId", ProductRootId);
          context.commit("setCachedOrganizationDataSourcesCustomFieldOrganizationRootId", OrganizationRootId);
          await timeOperation(async () => {
            const formattedUrl = `${organizationCustomFieldsUrl}?productrootid=${ProductRootId}&organizationrootid=${OrganizationRootId}&organizationdatasourcerootid=0&canusecache=${CanUseServerCache}&includemetadata=true`;
            const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
            context.commit("setOrganizationDataSourceCustomFields", data);
          }, "organizationCustomFields - getOrganizationCustomFields");
        }
      }
    },
    async addOrganizationCustomField(context, organizationCustomField) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: organizationCustomFieldsUrl, Payload: organizationCustomField });
        if (data?.RootId > 0) {
          organizationCustomField.RootId = data.RootId;
          context.commit("_addOrganizationCustomField", data);
        }
        return data;
      }, "organizationCustomFields - addOrganizationCustomField");
    },
    async updateOrganizationCustomField(context, organizationCustomField) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationCustomFieldsUrl}/${Number(organizationCustomField?.RootId || 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationCustomField });
        context.commit("_updateOrganizationCustomField", data);
      }, "organizationCustomFields - updateOrganizationCustomField");
    },
    async enableOrganizationCustomField(context, organizationCustomField) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationCustomFieldsUrl}/${Number(organizationCustomField?.RootId || 0)}/updateenabledstatus`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: { ...organizationCustomField, Enabled: true } });
        context.commit("_updateOrganizationCustomFieldEnabled", data);
      }, "organizationCustomFields - enableOrganizationCustomField");
    },
    async disableOrganizationCustomField(context, organizationCustomField) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationCustomFieldsUrl}/${Number(organizationCustomField?.RootId || 0)}/updateenabledstatus`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: { ...organizationCustomField, Enabled: false } });
        context.commit("_updateOrganizationCustomFieldEnabled", data);
      }, "organizationCustomFields - disableOrganizationCustomField");
    },
    async updateOrganizationCustomFieldAlias(context, organizationCustomField) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationCustomFieldsUrl}/${Number(organizationCustomField?.RootId || 0)}/updatealias`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationCustomField });
        context.commit("_updateOrganizationCustomFieldAlias", data);
      }, "organizationCustomFields - updateOrganizationCustomFieldAlias");
    },
    async removeOrganizationCustomField(context, organizationCustomField) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationCustomFieldsUrl}/${Number(organizationCustomField?.RootId || 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeOrganizationCustomField", organizationCustomField);
      }, "organizationCustomFields - removeOrganizationCustomField");
    },
    async applyOrganizationCustomField(context, appliedCustomField) {
      let res = null;
      await timeOperation(async () => {
        const formattedUrl = `${organizationCustomFieldsUrl}/setcustomfield`;
        res = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: appliedCustomField });
      }, "organizationCustomFields - applyOrganizationCustomField");
      return res?.Result || false;
    },
    async applyOrganizationCustomFields(context, appliedCustomFields) {
      return timeOperation(async () => {
        if (appliedCustomFields?.length > 0) {
          const formattedUrl = `${organizationCustomFieldsUrl}/applycustomfields`;
          return await context.dispatch(ApiRequest, {Verb: PostVerb, FormattedUrl: formattedUrl, Payload: appliedCustomFields});
        }
      }, "organizationCustomFields - applyOrganizationCustomFields");
    },
  },
};
