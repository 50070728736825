import {ApiRequest, GetVerb, PostVerb, DeleteVerb, appliedTagsUrl} from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    appliedTags: [],
  },
  getters: {},
  mutations: {
    setAppliedTagData(state, appliedTagsData) {
      state.appliedTags = appliedTagsData ?? [];
    },
    clearAppliedTags(state) {
      state.appliedTags = [];
    },
    _addAppliedTag(state, appliedTag) {
      const appliedTagsData = _.cloneDeep(state.appliedTags) ?? [];
      appliedTagsData.push(appliedTag);
      state.appliedTags = appliedTagsData ?? [];
    },
    _removeAppliedTag(state, deleteAppliedTag) {
      const rootId = Number(deleteAppliedTag?.RootId ?? 0);
      if (rootId > 0) {
        let appliedTagsData = _.cloneDeep(state.appliedTags) ?? [];
        state.appliedTags = [...appliedTagsData.filter((appliedTag) => Number(appliedTag?.RootId ?? 0) !== rootId)] ?? [];
      }
    },
  },
  actions: {
    async getAppliedTags(context, { OrganizationRootId, UserRootId, RecordRootId, OrganizationDataSourceRootId }) {
      await timeOperation(async () => {
        const formattedUrl = `${appliedTagsUrl}?organizationrootid=${Number(OrganizationRootId ?? 0)}&userrootid=${Number(UserRootId ?? 0)}&recordrootid=${Number(
          RecordRootId ?? 0
        )}&organizationdatasourcerootid=${String(OrganizationDataSourceRootId ?? "")}&includemetadata=true`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setAppliedTagData", data);
      }, "appliedTags - getAppliedTags");
    },
    async addAppliedTag(context, appliedTag) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: appliedTagsUrl, Payload: appliedTag });
        if (data?.RootId > 0) {
          appliedTag.RootId = data.RootId;
          context.commit("_addAppliedTag", data);
        }
        return data;
      }, "appliedTags - addAppliedTag");
    },
    async addAppliedTags(context, appliedTags) {
      return timeOperation(async () => {
        if (appliedTags?.length > 0) {
          const formattedUrl = `${appliedTagsUrl}/applymultipletags`;
          return await context.dispatch(ApiRequest, {Verb: PostVerb, FormattedUrl: formattedUrl, Payload: appliedTags});
        }
      }, "organizationTags - applyOrganizationTags");
    },
    async removeAppliedTag(context, appliedTagRootId) {
      return timeOperation(async () => {
        const formattedUrl = `${appliedTagsUrl}/${Number(appliedTagRootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        if (data?.RootId > 0) {
          context.commit("_removeAppliedTag", data);
        }
        return data;
      }, "appliedTags - removeAppliedTag");
    },
    async removeAppliedTags(context, appliedTags) {
      await timeOperation(async () => {
        if (appliedTags?.length > 0) {
          const formattedUrl = `${appliedTagsUrl}/deletemultiple`;
          const removedAppliedTags = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: appliedTags });
          if (removedAppliedTags?.length > 0)
            removedAppliedTags.forEach((removedAppliedTag) => {
              context.commit("_removeAppliedTag", removedAppliedTag);
            });
        }
      }, "appliedTags - removeAppliedTags");
    },
  },
};
