import {ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, organizationCustomUserFieldsUrl} from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    organizationDataSourceCustomUserFields: {},
    cachedOrganizationDataSourcesCustomUserFieldProductRootId: -1,
    cachedOrganizationDataSourcesCustomUserFieldOrganizationRootId: -1,
  },
  getters: {
    getOrganizationCustomUserFieldByRootId: (state) => (rootId) => {
      const organizationCustomUserFields = _.reduce(
        state.organizationDataSourceCustomUserFields,
        (result, listOfOrganizationCustomUserFields) => {
          if (listOfOrganizationCustomUserFields?.length > 0) {
            listOfOrganizationCustomUserFields.forEach((organizationCustomUserField) => {
              result.push(organizationCustomUserField);
            });
          }
          return result;
        },
        []
      );
      const organizationCustomUserField =
        organizationCustomUserFields.find((organizationCustomUserField) => Number(organizationCustomUserField?.RootId || 0) === Number(rootId || 0)) || null;
      return organizationCustomUserField?.RootId > 0 ? _.cloneDeep(organizationCustomUserField) : null;
    },
  },
  mutations: {
    setOrganizationDataSourceCustomUserFields(state, data) {
      let organizationDataSourceCustomUserFields = {};
      data?.forEach((organizationCustomUserField) => {
        const organizationDataSourceRootId = Number(organizationCustomUserField?.OrganizationDataSourceRootId || 0);
        if (organizationDataSourceRootId > 0) {
          if (!organizationDataSourceCustomUserFields.hasOwnProperty(organizationDataSourceRootId)) {
            organizationDataSourceCustomUserFields[organizationDataSourceRootId] = [];
          }
          organizationDataSourceCustomUserFields[organizationDataSourceRootId].push(_.cloneDeep(organizationCustomUserField));
        }
      });
      state.organizationDataSourceCustomUserFields = organizationDataSourceCustomUserFields;
    },
    clearOrganizationDataSourceCustomUserFields(state) {
      state.organizationDataSourceCustomUserFields = {};
      state.cachedOrganizationDataSourcesCustomUserFieldProductRootId = -1;
      state.cachedOrganizationDataSourcesCustomUserFieldOrganizationRootId = -1;
    },
    setCachedOrganizationDataSourcesCustomUserFieldProductRootId(state, cachedOrganizationDataSourcesCustomUserFieldProductRootId) {
      state.cachedOrganizationDataSourcesCustomUserFieldProductRootId = Number(cachedOrganizationDataSourcesCustomUserFieldProductRootId || -1);
    },
    setCachedOrganizationDataSourcesCustomUserFieldOrganizationRootId(state, cachedOrganizationDataSourcesCustomUserFieldOrganizationRootId) {
      state.cachedOrganizationDataSourcesCustomUserFieldOrganizationRootId = Number(cachedOrganizationDataSourcesCustomUserFieldOrganizationRootId || -1);
    },
    _addOrganizationCustomUserField(state, organizationCustomUserField) {
      if (organizationCustomUserField?.RootId > 0) {
        const organizationDataSourceRootId = Number(organizationCustomUserField?.OrganizationDataSourceRootId || 0);
        if (state.organizationDataSourceCustomUserFields.hasOwnProperty(organizationDataSourceRootId)) {
          state.organizationDataSourceCustomUserFields[organizationDataSourceRootId].push(organizationCustomUserField);
          state.organizationDataSourceCustomUserFields[organizationDataSourceRootId] =
            state.organizationDataSourceCustomUserFields[organizationDataSourceRootId].sort((ocuf1, ocuf2) => (ocuf1.CustomUserFieldIndex > ocuf2.CustomUserFieldIndex ? 1 : -1)) ||
            [];
        } else {
          state.organizationDataSourceCustomUserFields[organizationDataSourceRootId] = [organizationCustomUserField];
        }
      }
    },
    _updateOrganizationCustomUserField(state, updateOrganizationCustomUserField) {
      const rootId = Number(updateOrganizationCustomUserField?.RootId || 0);
      if (rootId > 0) {
        const organizationDataSourceRootId = Number(updateOrganizationCustomUserField?.OrganizationDataSourceRootId || 0);
        if (state.organizationDataSourceCustomUserFields.hasOwnProperty(organizationDataSourceRootId)) {
          let organizationCustomUserFieldsData = _.cloneDeep(state.organizationDataSourceCustomUserFields[organizationDataSourceRootId]) || [];
          organizationCustomUserFieldsData =
            [
              ...organizationCustomUserFieldsData.filter((organizationCustomUserField) => Number(organizationCustomUserField?.RootId || 0) !== rootId),
              _.cloneDeep(updateOrganizationCustomUserField),
            ] || [];
          state.organizationDataSourceCustomUserFields[organizationDataSourceRootId] =
            (
              _.map(organizationCustomUserFieldsData, (organizationCustomUserField) => {
                return { ...organizationCustomUserField, CanEdit: Number(organizationCustomUserField.ProductCustomUserFieldRootId || 0) < 1 };
              }) || []
            ).sort((ocuf1, ocuf2) => (ocuf1.CustomUserFieldIndex > ocuf2.CustomUserFieldIndex ? 1 : -1)) || [];
        } else {
          state.organizationDataSourceCustomUserFields[organizationDataSourceRootId] = [updateOrganizationCustomUserField];
        }
      }
    },
    _updateOrganizationCustomUserFieldEnabled(state, updateOrganizationCustomUserField) {
      const rootId = Number(updateOrganizationCustomUserField?.RootId || 0);
      if (rootId > 0) {
        const organizationDataSourceRootId = Number(updateOrganizationCustomUserField?.OrganizationDataSourceRootId || 0);
        if (state.organizationDataSourceCustomUserFields.hasOwnProperty(organizationDataSourceRootId)) {
          let organizationCustomUserField =
            state.organizationDataSourceCustomUserFields[organizationDataSourceRootId]?.find(
              (organizationCustomUserField) => Number(organizationCustomUserField?.RootId || 0) === rootId
            ) || null;
          if (organizationCustomUserField?.RootId > 0) {
            organizationCustomUserField.Enabled = updateOrganizationCustomUserField.Enabled;
          }
        }
      }
    },
    _updateOrganizationCustomUserFieldAlias(state, updateOrganizationCustomUserField) {
      const rootId = Number(updateOrganizationCustomUserField?.RootId || 0);
      if (rootId > 0) {
        const organizationDataSourceRootId = Number(updateOrganizationCustomUserField?.OrganizationDataSourceRootId || 0);
        if (state.organizationDataSourceCustomUserFields.hasOwnProperty(organizationDataSourceRootId)) {
          let organizationCustomUserField =
            state.organizationDataSourceCustomUserFields[organizationDataSourceRootId]?.find(
              (organizationCustomUserField) => Number(organizationCustomUserField?.RootId || 0) === rootId
            ) || null;
          if (organizationCustomUserField?.RootId > 0) {
            organizationCustomUserField.Alias = updateOrganizationCustomUserField.Alias;
          }
        }
      }
    },
    _removeOrganizationCustomUserField(state, deleteOrganizationCustomUserField) {
      const rootId = Number(deleteOrganizationCustomUserField?.RootId || 0);
      if (rootId > 0) {
        const organizationDataSourceRootId = Number(deleteOrganizationCustomUserField?.OrganizationDataSourceRootId || 0);
        if (state.organizationDataSourceCustomUserFields.hasOwnProperty(organizationDataSourceRootId)) {
          let organizationCustomUserFieldsData = _.cloneDeep(state.organizationDataSourceCustomUserFields[organizationDataSourceRootId]) || [];
          organizationCustomUserFieldsData =
            [...organizationCustomUserFieldsData.filter((organizationCustomUserField) => Number(organizationCustomUserField?.RootId || 0) !== rootId)] || [];
          state.organizationDataSourceCustomUserFields[organizationDataSourceRootId] =
            (
              _.map(organizationCustomUserFieldsData, (organizationCustomUserField) => {
                return { ...organizationCustomUserField, CanEdit: Number(organizationCustomUserField.ProductCustomUserFieldRootId || 0) < 1 };
              }) || []
            ).sort((ocuf1, ocuf2) => (ocuf1.CustomUserFieldIndex > ocuf2.CustomUserFieldIndex ? 1 : -1)) || [];
        }
      }
    },
  },
  actions: {
    async getOrganizationCustomUserFields(context, { ProductRootId, OrganizationRootId, UseCache, CanUseServerCache }) {
      ProductRootId = Number(ProductRootId || 0);
      OrganizationRootId = Number(OrganizationRootId || 0);
      UseCache = UseCache || false;
      CanUseServerCache = CanUseServerCache || true;
      if ((OrganizationRootId > 0) && (ProductRootId > 0)) {
        const cachedProductRootId = Number(context.state.cachedOrganizationDataSourcesCustomUserFieldProductRootId || 0);
        const cachedOrganizationRootId = Number(context.state.cachedOrganizationDataSourcesCustomUserFieldOrganizationRootId || 0);
        const dirtyCache = !((ProductRootId === cachedProductRootId) && (OrganizationRootId === cachedOrganizationRootId));
        if (!UseCache || dirtyCache) {
          context.commit("setCachedOrganizationDataSourcesCustomUserFieldProductRootId", ProductRootId);
          context.commit("setCachedOrganizationDataSourcesCustomUserFieldOrganizationRootId", OrganizationRootId);
          await timeOperation(async () => {
            const formattedUrl = `${organizationCustomUserFieldsUrl}?productrootid=${ProductRootId}&organizationrootid=${OrganizationRootId}&organizationdatasourcerootid=0&canusecache=${CanUseServerCache}&includemetadata=true`;
            const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
            context.commit("setOrganizationDataSourceCustomUserFields", data);
          }, "organizationCustomUserFields - getOrganizationCustomUserFields");
        }
      }
    },
    async addOrganizationCustomUserField(context, organizationCustomUserField) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: organizationCustomUserFieldsUrl, Payload: organizationCustomUserField });
        if (data?.RootId > 0) {
          organizationCustomUserField.RootId = data.RootId;
          context.commit("_addOrganizationCustomUserField", data);
        }
        return data;
      }, "organizationCustomUserFields - addOrganizationCustomUserField");
    },
    async updateOrganizationCustomUserField(context, organizationCustomUserField) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationCustomUserFieldsUrl}/${Number(organizationCustomUserField?.RootId || 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationCustomUserField });
        context.commit("_updateOrganizationCustomUserField", data);
      }, "organizationCustomUserFields - updateOrganizationCustomUserField");
    },
    async enableOrganizationCustomUserField(context, organizationCustomUserField) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationCustomUserFieldsUrl}/${Number(organizationCustomUserField?.RootId || 0)}/updateenabledstatus`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: { ...organizationCustomUserField, Enabled: true } });
        context.commit("_updateOrganizationCustomUserFieldEnabled", data);
      }, "organizationCustomUserFields - enableOrganizationCustomUserField");
    },
    async disableOrganizationCustomUserField(context, organizationCustomUserField) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationCustomUserFieldsUrl}/${Number(organizationCustomUserField?.RootId || 0)}/updateenabledstatus`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: { ...organizationCustomUserField, Enabled: false } });
        context.commit("_updateOrganizationCustomUserFieldEnabled", data);
      }, "organizationCustomUserFields - disableOrganizationCustomUserField");
    },
    async updateOrganizationCustomUserFieldAlias(context, organizationCustomUserField) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationCustomUserFieldsUrl}/${Number(organizationCustomUserField?.RootId || 0)}/updatealias`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationCustomUserField });
        context.commit("_updateOrganizationCustomUserFieldAlias", data);
      }, "organizationCustomUserFields - updateOrganizationCustomUserFieldAlias");
    },
    async removeOrganizationCustomUserField(context, organizationCustomUserField) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationCustomUserFieldsUrl}/${Number(organizationCustomUserField?.RootId || 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeOrganizationCustomUserField", organizationCustomUserField);
      }, "organizationCustomUserFields - removeOrganizationCustomUserField");
    },
    async applyOrganizationCustomUserField(context, appliedCustomUserField) {
      let res = null;
      await timeOperation(async () => {
        const formattedUrl = `${organizationCustomUserFieldsUrl}/setcustomuserfield`;
        res = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: appliedCustomUserField });
      }, "organizationCustomUserFields - applyOrganizationCustomUserField");
      return res?.Result || false;
    },
    async applyOrganizationCustomUserFields(context, appliedCustomUserFields) {
      return timeOperation(async () => {
        if (appliedCustomUserFields?.length > 0) {
          const formattedUrl = `${organizationCustomUserFieldsUrl}/applycustomuserfields`;
          return await context.dispatch(ApiRequest, {Verb: PostVerb, FormattedUrl: formattedUrl, Payload: appliedCustomUserFields});
        }
      }, "organizationCustomUserFields - applyOrganizationCustomUserFields");
    },
  },
};
